import React from "react"
import {___} from "../translate"

import {ReactComponent as CarSearch} from "../images/svg/car-search.svg"

const CurrentVehicle = ({currentVehicle, resetVehicle})=>{
  return <div className="current-car">
    <h3>{___("Vald bil")}</h3>
    <div className="vehicle">
      <div className="regno"><div className="country"><div className="inner"></div></div><div className="text">{currentVehicle.nr}</div></div>
      <div>{currentVehicle.manufacturer} </div>
      <div>{currentVehicle.model} </div>
      <div>{currentVehicle.name} </div>
      <div>({currentVehicle.year_start} — {currentVehicle.year_end})</div>
    </div>

    <div onClick={()=>{resetVehicle()}} className="ml-auto reset-vehicle">
      <span className="btn btn-outline-dark"><CarSearch/>{___("Välj annan bilmodell")}</span>
    </div>

  </div>
}


export default CurrentVehicle
