import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './styles/fonts.scss';
import './styles/index.scss';

import App from './app';
import {isHandofUrl} from "./utils/device"

import {setup_fixtures} from "./fixtures"


require(`./styles/${process.env.REACT_APP_MODE}.scss`)





Sentry.init({
  dsn: "https://4bcd721d82634ba792272581feefff5f@o237378.ingest.sentry.io/5555327",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});




let bootstrapped = false;

const bootstrap = (props) => {
  bootstrapped = true;
  ReactDOM.render(<App {...props}/>, document.getElementById('root'));
}



const MODE = process.env.REACT_APP_MODE
const MODE_APP = "app"
const MODE_KIOSK = "kiosk"

if(MODE === MODE_APP){

  const handOfSession = isHandofUrl()
  bootstrap({handOfSession, mode: MODE})


}else if(MODE === MODE_KIOSK){


  function onMessage(event){
    var message = JSON.parse(event.data)

    if(message && message.type === "data"){
      if(! bootstrapped){
        bootstrap({data: message, mode: MODE})
      }
    }
  }

  window.addEventListener("message", onMessage, false);

  if(process.env.NODE_ENV === "development"){
    setup_fixtures()
  }

  // setup_fixtures()

}else {

  console.error(`unknown mode ${MODE}`)

}
