import React, { Component } from 'react';


const TIMER_INTERVALL = 10 * 1000;


const IdleTimer = ({children, onReset, resetIntervall})=>{
    const [reset, setReset] = React.useState(false)

    const resetRef = React.useRef(false)
    const idleTime = React.useRef(0)
    const mouseInterupt = React.useRef(false)
    const RESET_INTERVALL = resetIntervall * 1000



    const onIdleTimer = React.useCallback(()=>{

      if(mouseInterupt.current){

        idleTime.current = idleTime.current + TIMER_INTERVALL;

        console.log(`onTimer: ${idleTime.current/1000} s`)

        if (idleTime.current >= RESET_INTERVALL) {
          idleTime.current = 0;

          console.log("reset");
          mouseInterupt.current = false;

          // Flip reset switch
          resetRef.current = !resetRef.current
          setReset(resetRef.current)
          onReset()

        }
      }
    }, [])


    const onTouch = React.useCallback((event)=>{
      console.log("touch");

      idleTime.current = 0;
      mouseInterupt.current = true;

    }, [])


    React.useEffect(()=>{
      window.addEventListener('touchstart', onTouch)

      return ()=>{
        window.removeEventListener('touchstart', onTouch)
      }

    }, [])


    React.useEffect(()=>{
      if( ! resetIntervall){
        console.log("no reset interval")
        return
      }

      const idleInterval = setInterval(onIdleTimer, TIMER_INTERVALL);
      return ()=>{
        clearInterval(idleInterval)
      }
    }, [])

    console.log(reset)


    return children(reset)

}

  export default IdleTimer
