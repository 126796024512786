
//
export const FORM_UUID = "7948C3FB68E3495CA57E08A986B4809C";

export const LANGUAGE_SE = "SE";
export const LANGUAGE_NO = "NO";
export const LANGUAGE_DK = "DK";
export const LANGUAGE_GB = "GB";


export const PAGE_INDEX = 0;
export const PAGE_FIND_CAR = 1;
export const PAGE_CHOOSE_TYPE = 2;
export const PAGE_PRODUCTS = 3;

export const MODE_CARPARTS = "carparts";
