
import React, { Component } from 'react';
import axios from "axios"
import {FIND_CAR_LICENSE_PLATE} from "./constants"
import categories, {OIL_CATEGORY} from "./categories"

import StartPage from "./pages/start"
import VehicleLicensePage from "./pages/vehicle-license"
import ProductsPage from "./pages/products"
import SubCategoryPage from "./pages/subcategory"

import BottomBar from "./components/bottom-bar"
import VehicleInfo from "./components/current-vehicle"
import IdleTimer from "./components/idle-timer"


import AppContext from "./context"

import {___} from "./translate"

import {LANGUAGE_SE, LANGUAGE_NO} from "./constants"

import {set_language} from "./translate"

const PAGE_START = "start";
const PAGE_SUBCATEGORY = "subcategory"
const PAGE_PRODUCTS = "products";
const PAGE_VEHICLE_LICENSE = "vehicle-license";
const PAGE_VEHICLE_MANUAL = "vehicle-manual";








const App = ({mode, data, handOfSession})=>{

  const [currentPage, setCurrentPage] =  React.useState(PAGE_START)
  const [currentVehicle, setCurrentVehicle] =  React.useState(null)
  const [currentCategory, setCurrentCategory] =  React.useState(null)
  const [currentSubcategory, setCurrentSubcategory] =  React.useState(null)

  const [language, setLanguage] = React.useState(LANGUAGE_SE)

  const [handOfToken, setHandofToken] = React.useState()
  const [handOfTokenIsInvalid, setHandofTokenIsInvalid] = React.useState(false)

  console.log(mode)


  const resetVehicle = React.useCallback(()=>{
    // Called from bottom bar
    setCurrentPage(PAGE_VEHICLE_LICENSE)
    setCurrentVehicle(null)
  }, [])

  const home = React.useCallback(()=>{

    if(mode === 'kiosk'){
      window.webViewBridge.send('area-show', {})
    }

    // Called from bottom bar
    setCurrentPage(PAGE_START)
    setCurrentCategory(null)
    setCurrentSubcategory(null)
    setCurrentVehicle(null)
  }, [])


  const selectSubcategory = React.useCallback((subcategory)=>{
    // Called from product page to choose another subcategory
    setCurrentPage(subcategory ? PAGE_PRODUCTS : PAGE_SUBCATEGORY)
    setCurrentSubcategory(subcategory)
  }, [])

  const selectCategory = React.useCallback((category)=>{
    // Called from bottom bar or start
    if(! category){
      home()
      return
    }

    if(mode === 'kiosk'){
      window.webViewBridge.send('area-hide', {})
    }


    let page;
    if(! currentVehicle){
      page = PAGE_VEHICLE_LICENSE;
    }else if(category.subcategories){
      page = PAGE_SUBCATEGORY;
    }else{
      page = PAGE_PRODUCTS;
    }

    setCurrentPage(page)
    setCurrentCategory(category)
    setCurrentSubcategory(null)

  }, [currentVehicle])


  const selectVehicle = React.useCallback((vehicle)=>{
    vehicle.form_label = `${vehicle.manufacturer}-${vehicle.model}-${vehicle.name} (${vehicle.id})`
    // window.tm.send({"action": "vehicleFound", "car": vehicle.form_label}, FORM_UUID)

    // Called from vehicle views
    let page
    if(currentCategory.subcategories){
      page = PAGE_SUBCATEGORY
    }else {
      page = PAGE_PRODUCTS
    }

    setCurrentVehicle(vehicle)
    setCurrentPage(page)

  }, [currentCategory])



  React.useEffect(()=>{
    // Triggered with user scans qr code

    const load = async()=>{
      setHandofToken()
      setHandofTokenIsInvalid(false)

      try{
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/app-from-kiosk`, {token:handOfSession})
        setHandofToken(response.data.token)
      }catch(er){
        setHandofTokenIsInvalid(true)

      }



    }
    if(mode === "app"){
      // Mobile app
      load()

    }

  }, [handOfSession])


  const updateAreaSize = React.useCallback((size)=>{

    // Kiosk
    if(mode === 'kiosk'){
      window.webViewBridge.send('area-size', size)
    }


  }, [])


  if(mode === "app"){

    if(!handOfSession || handOfTokenIsInvalid){
      return <div className="app-splash">
        Scanna QR-kod i butiken för att fortsätta
      </div>

    }else if(! handOfToken){
      return <div className="app-splash">
        <span className="spinner-border"/>
      </div>
    }

  }


  return <IdleTimer resetIntervall={mode === "app" ? 0 : 120} onReset={()=>{
    console.log("reset home")
    home()

  }}>{({reset})=>(

    <AppContext.Provider value={{mode, data, handOfToken}}>
      <div className={`app mode-${mode}`}>


        {
          currentVehicle && <VehicleInfo currentVehicle={currentVehicle} resetVehicle={resetVehicle}/>
        }


        {
          currentPage === PAGE_START &&
          <StartPage selectCategory={selectCategory} categories={categories} updateAreaSize={updateAreaSize}/>
        }
        {
          currentPage === PAGE_VEHICLE_LICENSE &&
          <VehicleLicensePage language={language} selectVehicle={selectVehicle}/>
        }


        {
          currentPage === PAGE_SUBCATEGORY &&
          <SubCategoryPage currentCategory={currentCategory} currentSubcategory={currentSubcategory} selectSubcategory={selectSubcategory}/>
        }

        {
          currentPage === PAGE_PRODUCTS &&
          <ProductsPage currentCategory={currentCategory} currentSubcategory={currentSubcategory} currentVehicle={currentVehicle} selectSubcategory={selectSubcategory}/>
        }

        {
          // Bottom bar
          currentPage !== PAGE_START && (<BottomBar
            categories={categories}
            currentCategory={currentCategory}
            currentVehicle={currentVehicle}
            selectCategory={selectCategory}
            resetVehicle={resetVehicle}

          />)
        }

      </div>
    </AppContext.Provider>
  )}</IdleTimer>
}

export default App;
