
import {LANGUAGE_SE} from "./constants"


const translate = {
	"NO": {

		// Common
		"Torkarblad": "Vindusvisker",
		"Billampor": "Billampe",
		"Bilbatterier": "Bilbatteri",
		"Olja": "Motorolje",
		"Glykol": "Frostvæske",

		"Hem": "Hjem",
		"Din bil": "Din bil",
		"Välj annan<br>bilmodell": "Velg en annen<br>bilmodell",

		// Index
		
		"Hitta rätt produkt till ditt fordon, tryck på en knapp här under": "Finn riktig produkt til din bil trykk på knappene under",

		// License
		"Hitta din bilmodell": "Finn din bilmodell",
		"Ange bilens registreringsnummer genom att trycka här under.": "Tast inn bilens registreringsnummer.",
		"Gå vidare": "Fortsett",
		"Ogiltigt registreringsnummer": "Ogiltigt registreringsnummer",
		"Ditt registreringsnummer kunde inte hittas.": "Vi kan dessverre ikke finne ditt registreringsnummer, kontakt betjeningen.",

		// Types
		"Visa bakrutan": "Vis bakrute",
		"Visa framrutan": "Vis frontrute",
		"Visa baksidan": "Vis bakpart",
		"Visa framsidan": "Vis front",
		"Välj den typ av torkarblad du letar efter": "Velg ønsket viskerplassering",
		"Välj den typ av billampor du letar efter": "Velg ønsket billampe",
		"Välj den typ av olja du letar efter": "Velg den oljetype du leter etter",
		"Halvljuslampor": "Nærlys",
		"Helljuslampor": "Fjernlys",
		"Sidoblinkers": "Blinklys - side",
		"Framblinkers": "Blinklys",
		"Dimljus fram": "Tåkelys",
		"Parkeringslampor": "Parklys",
		"Dimljus bak": "Tåkelys",
		"Bakljus": "Baklys",
		"Tredje bromsljus": "Bremselys",
		"Bromsljus": "Bremselys",
		"Lampa till nummerplåt": "Skiltlys",
		"Backlampor": "Ryggelys",
		"Bakblinkers": "Blinklys",
		"Förarsidan": "Førersiden",
		"Passagerarsidan": "Passasjersiden",
		"Torkarblad bak": "Bakrute",
		"Motorolja": "Motorolje",
		"Styrservoolja": "Styrservoolja",
		'Automatisk växellådsolja': "Automatgirolje",
		'Manuell växellådsolja': "Manuell växellådsolja",
		'Transmissionsolja': "Girolje",
		'Bromsvätska': "Bremsevæske",

		// Product options
		"Välj det alternativ som stämmer för din bil:": "Velg det alternativet som er riktig til din bil:",
		"Välj annan typ av torkarblad": "Velg en annen vindusvisker",
		"Välj annan typ av billampor": "Velg en annen billampe",
		"Välj annan typ av olja": "Velg en annen olje",

		// Products
		"Välj produkt": "Velg produkt",
		"Inga produkter finns i denna kategori för din bil.": "Ingen produkter finnes i denne kategorien for valgt bil",
		"Kontakta personal för ytterligare hjälp att hitta rätt produkt.": "Kontakt personalet for ytterligere hjelp til å finne rett produkt",
		"Ett oväntat fel har inträffat.": "En tilfeldig feil har oppstått",
		"Vänligen försök igen eller kontakta personal.": "Vennligst forsøk igjen, eller kontakt personalet",
		"Visa alternativ": "Vis alternativer",

		// Qr code scanning
		"Montering": "Montering",
		"Scanna qr-koden med din smartphone för guide!": "Scan QR-koden med din Smarttelefon for guiding",
		"Stäng": "Avslutt",

		"Rekommenderad": "Anbefalt",

		// Retail
		"Hitta din produkt": "Finn ditt produkt",
		"Välj annan produkt": "Velg et annet produkt"


	},
	"GB": {
		"Torkarblad": "Wiper blades",
		"Billampor": "Car bulbs",
		"Bilbatterier": "Car batteries",
		"Olja": "Oil",
		"Glykol": "Glycol",

		"Hem": "Home",
		"Din bil": "Your car",
		"Välj annan<br>bilmodell": "Select another<br>car",
		"Hitta rätt produkt till ditt fordon, tryck på en knapp här under": "Find the right product for your vehicle press a button here below",
		"Hitta din bilmodell": "Find your carmodel",
		"Ange bilens registreringsnummer genom att trycka här under.": "Enter the car's registration number by pressing below.",
		"Gå vidare": "Move on",
		"Ogiltigt registreringsnummer": "Invalid registration number",
		"Ditt registreringsnummer kunde inte hittas.": "Your licensplate number could not be found.",
		"Visa baksidan": "Show back",
		"Visa framsidan": "Show front",
		"Visa bakrutan": "Show back",
		"Visa framrutan": "Show front",
		"Välj den typ av torkarblad du letar efter": "Select the type of wipers you're looking for",
		"Välj den typ av billampor du letar efter": "Select the type of car lamps you are looking for",
		"Välj den typ av olja du letar efter": "Select the type of oil you're looking for",
		"Halvljuslampor": "Dipped beam lights",
		"Helljuslampor": "Main beam lights",
		"Sidoblinkers": "Turn signal, side",
		"Framblinkers": "Rront flashers",
		"Dimljus fram": "Rront fog lights",
		"Parkeringslampor": "Parking Lamps",
		"Dimljus bak": "Rear fog light",
		"Bakljus": "Taillight",
		"Tredje bromsljus": "Third brake light",
		"Bromsljus": "Brake lights",
		"Lampa till nummerplåt": "Lamp for number plate",
		"Backlampor": "Back Lights",
		"Bakblinkers": "Turn signal, rear",
		"Förarsidan": "Driver's side",
		"Passagerarsidan": "Passenger side",
		"Torkarblad bak": "Wiper blade rear",
		"Motorolja": "Engine oil",
		"Växellådsolja": "Transmission oil",
		"Styrservoolja": "Power steering fluid",
		"Välj det alternativ som stämmer för din bil:": "Choose the option that's right for your car:",
		"Välj annan typ av torkarblad": "Choose another type of wiper blades",
		"Välj annan typ av billampor": "Choose another type of car lamps",
		"Välj annan typ av olja": "Choose another type of oil",
		"Välj produkt": "Select product",
		"Inga produkter finns i denna kategori för din bil.": "No products available in this category for your car.",
		"Kontakta personal för ytterligare hjälp att hitta rätt produkt.": "Contact staff for further assistance to find the right product.",
		"Ett oväntat fel har inträffat.": "An unexpected error has occurred.",
		"Vänligen försök igen eller kontakta personal.": "Please try again or contact the staff.",
		"Visa alternativ": "Display options",
		"Montering": "Mounting",
		"Scanna qr-koden med din smartphone för guide!": "Scan the QR code with your smartphone guide!",
		"Stäng": "Close",
		"Tillbaka": "Back",
		"Registreringsskylt": "License plate",
		"Bilmodell": "Car model",
		"Visa bara vanliga tillverkare": "Only show common manufacturers",
		"Visa flera tillverkare": "Show more manufacturers",

		"Hitta din produkt": "Find your product",
		"Välj annan produkt": "Find another product"

	},

	'SE': {
		"Ett oväntat fel har inträffat.": "Saknar internetåtkomst."
	},

	'DK': {
		"Välj annan produkt": "Vælg andet produkt",
		"Hitta din produkt": "Find dit produkt",
		"Hem": "Hjem",
		"Tillbaka": "Tilbage",
		"Skriv ut": "Print",
		"Stäng": "Luk"
	}

};


let language = LANGUAGE_SE;

export function set_language(language_code){
	console.log(`Updating language: ${language_code}`)
	language = language_code;
}

export function ___(string){

	const lang = translate[language]

	if(! lang){
		return string;
	}

	const translated_string = lang[string];

	if(! translated_string){
		return string;
	}
	return translated_string;
}
