
import { ReactComponent as CarIcon } from './images/svg/car.svg';
import { ReactComponent as LampIcon } from './images/svg/lamp.svg';
import { ReactComponent as WipersIcon } from './images/svg/wipers.svg';
import { ReactComponent as OilIcon } from './images/svg/oil.svg';


import BacklamporIcon from "./images/subcategory/category_subcategory_backlampor.png"
import BakblinkersIcon from "./images/subcategory/category_subcategory_bakblinkers.png"
import BakljusIcon from "./images/subcategory/category_subcategory_bakljus.png"
import BromsljusIcon from "./images/subcategory/category_subcategory_bromsljus.png"
import DimljusBakIcon from "./images/subcategory/category_subcategory_dimljus_bak.png"
import DimljusFramIcon from "./images/subcategory/category_subcategory_dimljus_fram.png"
import FramblinkersIcon from "./images/subcategory/category_subcategory_framblinkers.png"
import HalvljusIcon from "./images/subcategory/category_subcategory_halvljus.png"
import HelljusIcon from "./images/subcategory/category_subcategory_helljus.png"
import NummerlampaIcon from "./images/subcategory/category_subcategory_nummerlampa.png"
import ParkeringslamporIcon from "./images/subcategory/category_subcategory_parkeringslampor.png"
import SidoblinkersIcon from "./images/subcategory/category_subcategory_sidoblinkers.png"
import TredjeBromsljusIcon from "./images/subcategory/category_subcategory_tredje_bromsljus.png"
import WiperIcon from "./images/subcategory/category_subcategory_wiper.png"


export const SubcategoryIcons = {
	"backlampor": BacklamporIcon,
	"bakblinkers": BakblinkersIcon,
	"bakljus": BakljusIcon,
	"bromsljus": BromsljusIcon,
	"dimljus_bak": DimljusBakIcon,
	"dimljus_fram": DimljusFramIcon,
	"framblinkers": FramblinkersIcon,
	"halvljus": HalvljusIcon,
	"helljus": HelljusIcon,
	"nummerlampa": NummerlampaIcon,
	"parkeringslampor": ParkeringslamporIcon,
	"sidoblinkers": SidoblinkersIcon,
	"tredje_bromsljus": TredjeBromsljusIcon,
	"wiper": WiperIcon,
}



export const POSITION_FRONT = 0
export const POSITION_BACK = 1
// const POSITION_NONE = null

export const WIPERS_CATEGORY_ID = 1
export const LAMPS_CATEGORY_ID = 2
export const BATTERIES_CATEGORY_ID = 3
export const OIL_CATEGORY_ID = 4
export const ANTIFREEZE_CATEGORY_ID = 5


// Categories
export const WIPERS_DRIVER_SUBCATEGORY = 100
export const WIPERS_PASSENGER_SUBCATEGORY = 101
export const WIPERS_BACK_SUBCATEGORY = 102



let categories = []

export const WIPERS_CATEGORY = {

	"id": WIPERS_CATEGORY_ID,
	"name": "Torkarblad",

	"icon": WipersIcon,//"wiper",
	"subcategory_title": 'Välj den typ av torkarblad du letar efter',
	"subcategory_btn": 'Välj annan typ av torkarblad',
	"subcategory_buttons_on_car": true,

	// "subcategory_turn_car_front": 'Visa framrutan',
	// "subcategory_turn_car_back": 'Visa bakrutan',

	"subcategories": [
		{
			"id": 100,
			"name": "Förarsidan",
			"position": POSITION_FRONT,
			"line": [{x:571, y:301}, {x:741, y:198}],
			"icon":'wiper',
		},
		{
			"id": 101,
			"name": "Passagerarsidan",
			"position": POSITION_FRONT,
			"line": [{x:502, y:293}, {x:123, y:155}],
			"icon":'wiper',
		},
		{
			"id": 102,
			"name": "Torkarblad bak",
			"position": POSITION_FRONT,
			"line": [{x:700, y:300}, {x:850, y:320}],
			"icon":'wiper',
		}
	]
}

categories.push(WIPERS_CATEGORY)







export const LAMPS_CATEGORY = {
	"id": LAMPS_CATEGORY_ID,
	"name": "Billampor",


	"icon": LampIcon, //"lamp",
	"subcategory_title": 'Välj den typ av billampor du letar efter',
	"subcategory_btn": 'Välj annan typ av billampor',
	"subcategory_buttons_on_car": true,
	"subcategory_turn_car_front": 'Visa framsidan',
	"subcategory_turn_car_back": 'Visa baksidan',


	"subcategories": [
		{
			"id": 200,
			"name": 'Halvljuslampor',
			"position": POSITION_FRONT,
			"line": [{x:508, y:378}, {x:353, y:145}],
			"icon":'halvljus',
		},
		{
			"id": 201,
			"name": 'Helljuslampor',
			"position": POSITION_FRONT,
			"line": [{x:516, y:370}, {x:594, y:134}],
			"icon":'helljus',
		},
		{
			"id": 202,
			"name": 'Sidoblinkers',
			"position": POSITION_FRONT,
			"line": [{x:645, y:348}, {x:803, y:222}],
			"icon":'sidoblinkers',
		},
		{
			"id": 203,
			"name": 'Framblinkers',
			"position": POSITION_FRONT,
			"line": [{x:545, y:370}, {x:577, y:550}],
			"icon":'framblinkers',
		},
		{
			"id": 204,
			"name": 'Dimljus fram',
			"position": POSITION_FRONT,
			"line": [{x:525, y:444}, {x:333, y:554}],
			"icon":'dimljus_fram',
		},
		{
			"id": 205,
			"name": 'Parkeringslampor',
			"position": POSITION_FRONT,
			"line": [{x:482, y:380}, {x:120, y:258}],
			"icon":'parkeringslampor',
		},


		{
			"id": 206,
			"name": 'Dimljus bak',
			"position": POSITION_BACK,
			"line": [{x:544, y:310}, {x:113, y:185}],
			"icon":'dimljus_bak',
		},
		{
			"id": 207,
			"name": 'Bakljus',
			"position": POSITION_BACK,
			"line": [{x:594, y:304}, {x:339, y:137}],
			"icon":'bakljus',
		},
		{
			"id": 208,
			"name": 'Tredje bromsljus',
			"position": POSITION_BACK,
			"line": [{x:570, y:230}, {x:618, y:144}],
			"icon":'tredje_bromsljus',
		},
		{
			"id": 209,
			"name": 'Bromsljus',
			"position": POSITION_BACK,
			"line": [{x:795, y:305}, {x:796, y:194}],
			"icon":'bromsljus',
		},
		{
			"id": 210,
			"name": 'Lampa till nummerplåt',
			"position": POSITION_BACK,
			"line": [{x:695, y:328}, {x:551, y:557}],
			"icon":'nummerlampa',
		},
		{
			"id": 211,
			"name": 'Backlampor',
			"position": POSITION_BACK,
			"line": [{x:586, y:317}, {x:330, y:555}],
			"icon":'backlampor',
		},
		{
			"id": 212,
			"name": 'Bakblinkers',
			"position": POSITION_BACK,
			"line": [{x:556, y:329}, {x:65, y:426}],
			"icon":'bakblinkers',
		},

	]
}

categories.push(LAMPS_CATEGORY)





const GENERIC_BATTERY = 1

export const BATTERIES_CATEGORY = {
	"id": BATTERIES_CATEGORY_ID,
	"name": "Bilbatterier",
	"icon": CarIcon, //"battery",
}

categories.push(BATTERIES_CATEGORY)






//
export const OIL_MOTOR_SUBCATEGORY = 300
export const OIL_AUTO_GEARBOX_SUBCATEGORY = 301
export const OIL_MAN_GEARBOX_SUBCATEGORY = 302
export const OIL_SERVO_SUBCATEGORY = 303
export const OIL_TRANSMISION_SUBCATEGORY = 304
export const OIL_BRAKEFLUID_SUBCATEGORY = 305





export const OIL_CATEGORY = {
	"id": OIL_CATEGORY_ID,
	"name": "Olja",

	"icon": OilIcon, //"oil",
	"subcategory_title": 'Välj den typ av olja du letar efter',
	"subcategory_btn": 'Välj annan typ av olja',
	"subcategory_buttons_on_car": false,

	// "subcategories": [
	// 	{
	// 		"id": 300,
	// 		"name": "Motorolja",
	// 	},
	// 	{
	// 		"id": 301,
	// 		"name": 'Automatisk växellådsolja',
	// 	},
	// 	{
	// 		"id": 302,
	// 		"name": 'Manuell växellådsolja',
	// 	},
	// 	{
	// 		"id": 303,
	// 		"name": 'Styrservoolja',
	// 	},
	// 	{
	// 		"id": 304,
	// 		"name": 'Transmissionsolja',
	// 	},
	// 	{
	// 		"id": 305,
	// 		"name": 'Bromsvätska',
	// 	},
	// ]
}
categories.push(OIL_CATEGORY)




export const ANTIFREEZE_CATEGORY = {
	"id": ANTIFREEZE_CATEGORY_ID,
	"name": "Glykol",

	"icon": CarIcon, //"antifreeze"
}
categories.push(ANTIFREEZE_CATEGORY)

categories = [LAMPS_CATEGORY, WIPERS_CATEGORY, OIL_CATEGORY]

export default categories;
