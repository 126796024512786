import React, { Component } from 'react';

import QRCode from 'qrcode.react';


import {___} from "../translate"

import PlayIcon from "../images/btn_play.png"

import { Swiper, SwiperSlide } from 'swiper/react';



// import Modal from "../components/modal"

import ImportantIcon from "../images/products/important.png"



class ProductDetails extends Component {

	constructor(props) {
		super(props);

		this.state = {
			"showModal": false,
		};
	}

	getProductImageLarge(product){
		if(! product.img){
			return <this.props.currentCategory.icon/>
		}else if(product.img.image) {
			return <img alt={product.title} src={product.img.image}/>

		}
	}


	componentDidUpdate(prevProps, prevState, snapshot){
		if (this.props.currentProduct !== prevProps.currentProduct) {

			if(this.detail_scroller){
			    this.detail_scroller.withIScroll(function(iScroll) {
			      iScroll.scrollTo(0,0)
	    		})
			}

		}
	}



	openModal(product){



	}

	renderProductLinkInformation(product){
		if(! product.link_text && ! product.link_crit){
			return null
		}

		return (<div className="important-vehicle-info">
			<p>
				<img alt="" src={ImportantIcon}/>
				<b>{___("Viktig information om ditt valda fordon")}</b>
			</p>
			{ product.link_text && <div dangerouslySetInnerHTML={{__html: product.link_text}}/> }
			{ product.link_text && product.link_crit && <br/> }
			{ product.link_crit && <span dangerouslySetInnerHTML={{__html: product.link_crit}}/> }
		</div>

		)

	}


	render() {
		let product = this.props.currentProduct

		if(! product){
			return null;
		}

		let url = `http://uqr.se/u/${product.url || ""}/`


		return (

			<div className="product-details d-flex">


					<div className="image">
						{this.getProductImageLarge(product)}
						{ product.recommended && <div className="product-recommended">{___("Rekommenderad")}</div> }
					</div>

					{product.url &&  (
						<button className="btn btn-success video-button" onClick={()=>this.setState({"showModal": true})}>
							<img alt="play" src={PlayIcon}/><div><strong>{___("Montering")}</strong></div>
						</button>
					)}

					<div className='info'>
						<div className="title"><h3>{product.title}</h3></div>
						<div className="text">
							<div>
							{ product.important_text && ( <div stlye={{marginBottom:20}} dangerouslySetInnerHTML={{__html: product.important_text}}/> )}


							{ this.renderProductLinkInformation(product) }

							{ product.text && <div dangerouslySetInnerHTML={{__html: product.text}}/> }
							<br/>
							<div className="sku">
								<b>Art nr:</b> {product.nr ? product.nr : product.sku_nr}
							</div>
							{ product.ean_nr && <div className="ean"><b>EAN:</b> {product.ean_nr}</div> }
							<br/>
							</div>
						</div>
					</div>

			</div>
		)
	}
}

export default ProductDetails
